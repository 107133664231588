import { VerticalSpace } from 'components/space/vertical-space';
import { COLORS } from '../../helpers/constants';
import { Text } from './text';
import { WarningSection } from 'components/card/warning-section';

export const FormRequiredInfo = ({ isFamily = false }) => {
  return (
    <VerticalSpace size="small">
      <WarningSection>
        <Text color={COLORS.SECONDARY.GRAY_DARK}>
          {isFamily && <p>Անչափահաս երեխայի համար հայտը պետք է լրացնի ծնողը (խնամակալը կամ հոգաբարձուն)՝ հակառակ դեպքում դիմումը կմերժվի)</p>}
          <span style={{ color: COLORS.ALERT.RED }}>*</span>-ով նշված դաշտերում տվյալների մուտքագրումը պարտադիր է:
          <p>Նույնականացման տվյալներն անհրաժեշտ է լրացնել անձը հաստատող փաստաթղթի տվյալներին համապատասխան։</p>
        </Text>
      </WarningSection>
    </VerticalSpace>
  );
};
