import { useCallback, useState } from 'react';
import { Modal, Spin } from 'antd';
import { URL_CREATE_APPLICATION, URL_ENLARGE_FAMILY } from '../../../api/application/constants';
import { usePostNoMutate } from '../../../api/application/use-post-no-mutate';
import { useApplication } from '../../../context/applicaton-context';
import { Form } from '../form';
import { getDataToSave, initialValues } from './utils';

export const ApplicationFormWrapper = ({ children, ...props }) => {
  const [form] = Form.useForm();
  const { addData, submittedData, cityzenData, jump, step } = useApplication();
  const [enlargeFamData, setEnlargeFamData] = useState({});
  const applicationId = form.getFieldValue('applicationId');

  const resetFormFields = () => {
    form.resetFields(['bankId', 'accountNumber']);
  };

  const { isInitialLoading } = usePostNoMutate(
    applicationId ? URL_ENLARGE_FAMILY : URL_CREATE_APPLICATION,
    applicationId ? enlargeFamData : submittedData,
    {
      retry: false,
      enabled: step === 2,
      onSuccess: (data) => {
        if (data?.accepted) {
          Modal.success({
            title: 'Շնորհակալություն',
            content: <div dangerouslySetInnerHTML={{ __html: data.applicationCreateMessage }} />,
            closable: true,
            onCancel: () => (window.location.href = '/'),
            afterClose: () => (window.location.href = '/'),
            footer: false,
          });
        } else {
          Modal.error({
            title: 'Շնորհակալություն',
            content: <div dangerouslySetInnerHTML={{ __html: data.applicationCreateMessage }} />,
            closable: true,
            onCancel: () => (window.location.href = '/'),
            afterClose: () => (window.location.href = '/'),
            footer: false,
          });
        }
      },
      onError: (err) => {
        Modal.error({
          title: 'Տեղի է ունեցել սխալ',
          content: err.response?.data?.Message,
          footer: false,
          closable: true,
          onCancel: resetFormFields,
        });
      },
    }
  );

  const onFinish = useCallback(
    (values) => {
      if (applicationId) {
        const formattedData = getDataToSave({ form, values, step, citizenInfo: cityzenData });
        const dataToSubmit = {
          familyMembers: formattedData.familyData.familyMembers,
          applicationId,
          hasAgreedToPassTo3rdParty: formattedData.hasAgreedToPassTo3rdParty,
        };

        setEnlargeFamData(dataToSubmit);
        jump(step + 1);
      } else {
        const dataToSubmit = getDataToSave({ form, values, step, submittedData, citizenInfo: cityzenData });
        addData(dataToSubmit);
      }
      window.scrollTo(0, 0);
    },
    [addData, applicationId, cityzenData, form, jump, step, submittedData]
  );

  return (
    <Spin spinning={isInitialLoading}>
      <Form
        name="application-form"
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        layout="vertical"
        preserve
        {...props}
      >
        {children}
      </Form>
    </Spin>
  );
};
