import { useCallback, useMemo, useState } from 'react';
import { URL_VALIDATE_USER_DATA } from '../../api/benefit/constants';
import { SubmitButton } from '../../components/button';
import { PersonalInfo } from '../../components/form/application/personal-info';
import { RegistrationAddress } from '../../components/form/application/registration-address';
import { Form } from '../../components/form/form';
import { PersonDataSearchForm } from '../../components/form/person-data-search-form';
import { VerticalSpace } from '../../components/space/vertical-space';
import { FormRequiredInfo } from '../../components/typography/form-required-info';
import { IsMyDataTrue } from '../../components/form/application/is-my-data-true';
import { useApplication } from '../../context/applicaton-context';
import {
  citizenInfo,
  formItemActualAddressStepTwo,
  formItemAddressType,
  formItemDocStepTwo,
  formItemRegAddressStepTwo,
  formItemStepTwoParent,
} from './constants';
import { FormSectionCard } from '../../components/card/form-section-card';
import { getDocumentTypeProps, setFormCitizenData } from '../../components/form/application/utils';
import { Spin } from 'antd';
import { useCitizenSearchValidationForm } from 'hooks/application/citizen-search-validation-form';

// import { SimpleUtilityForm } from 'components/form/application/utility-accounts-form/simple-utility-form';
import { PeopleInSameAddress } from 'components/form/application/actual-residential-address/people-in-same-address';
import { useQueryClient } from '@tanstack/react-query';
import { GiveDataTo3rdParty } from 'components/form/application/give-data-to-3rd-party';
import { SuccessModal } from 'components/modal/success-modal';
import { BankInfo } from 'components/form/application/bank-info';

export const SecondStep = () => {
  const queryClient = useQueryClient();
  const { addCityzenData, cityzenData } = useApplication();
  // eslint-disable-next-line no-unused-vars
  const [disable, setDisable] = useState(true);
  const form = Form.useFormInstance();
  const formItemPropStepTwo = useCitizenSearchValidationForm();

  const onSuccess = useCallback(
    (data) => {
      setFormCitizenData(form, data);
      addCityzenData({ ...form.getFieldValue([formItemStepTwoParent, citizenInfo]), ...data });
    },
    [addCityzenData, form]
  );

  const onRemove = useCallback(
    (invalidateRequest, resetState) => {
      form.resetFields([['citizenData', 'citizenInfo']]);
      addCityzenData();
      resetState();
      queryClient.removeQueries(invalidateRequest);
    },
    [addCityzenData, form, queryClient]
  );

  const documentTypeProps = useMemo(
    () =>
      cityzenData?.documentNumber
        ? getDocumentTypeProps(form, [cityzenData?.documentNumber], formItemDocStepTwo.documentNumber.name)
        : {},
    [cityzenData?.documentNumber, form]
  );

  const hasMembersInSameAddressWatch = Form.useWatch([formItemStepTwoParent, 'hasMembersInSameAddress']);

  return (
    <Spin spinning={false}>
      <VerticalSpace size="large">
        <FormRequiredInfo />
        <FormSectionCard title="Անձնական տվյալներ">
          <PersonDataSearchForm
            formItemProp={formItemPropStepTwo}
            url={URL_VALIDATE_USER_DATA}
            onSuccess={onSuccess}
            setDisabled={!!cityzenData?.documentNumber}
            onRemove={onRemove}
            isApplicant={true}
          />
        </FormSectionCard>
        <PersonalInfo formItemDoc={formItemDocStepTwo} selectProps={documentTypeProps} viewMode={true} />

        <RegistrationAddress
          formItemRegAddress={formItemRegAddressStepTwo}
          formItemActualAddressStepTwo={formItemActualAddressStepTwo}
          formItemAddressType={formItemAddressType}
          actualAddressAutoFill={true}
        />
        {/* <SimpleUtilityForm /> */}
        <BankInfo setDisable={setDisable} />
        <PeopleInSameAddress />
        {hasMembersInSameAddressWatch === false && <GiveDataTo3rdParty />}
        {hasMembersInSameAddressWatch === false && <IsMyDataTrue />}
        {hasMembersInSameAddressWatch === false && <SuccessModal form={form} submitText={'Հաստատել'} />}
        {hasMembersInSameAddressWatch !== false && (
          <SubmitButton submitText={hasMembersInSameAddressWatch === false ? 'Հաստատել' : 'Հաջորդ'} />
        )}
      </VerticalSpace>
    </Spin>
  );
};
