import { FormSectionFullCard } from 'components/card/form-section-full-card';
import { FormItem } from '../form-item';
import { Space } from 'antd';
import { SecondaryText, Text } from 'components/typography';
import { COLORS } from 'helpers/constants';
import { Checkbox } from 'components/checkbox';

export const MinorChildrenAttachedApplication = ({ haveMinorChild }) => {
  return (
    <FormSectionFullCard>
      <Text strong>{haveMinorChild ? "Դիմումը հաստատելուց հետո Ձեր անչափահաս երեխաների տվյալները կկցվեն Ձեր դիմումին" : "Սեղմելով ուղարկել դուք կունենաք գլխավոր դիմումատուի կարգավիճակ"}</Text>
      <FormItem propName="checked" name="minorChildrenAttachedApplication" rules={[{ required: true }]}>
        <Checkbox.Group>
          <Space>
            <Checkbox value={true}>
              <SecondaryText color={COLORS.PRIMARY.BLUE}>Կարդացել եմ</SecondaryText>
            </Checkbox>
          </Space>
        </Checkbox.Group>
      </FormItem>
    </FormSectionFullCard>
  );
};
