import { Col, Row } from 'antd';
import { Datepicker } from '../datepicker';
import { Input } from '../input';
import { FormItem } from './form-item';
import { ArmenianInput } from 'components/input/armenian-input';

export const PersonMainData = ({ formItemProp = {}, disabled = false, section = 'general' }) => {
  return (
    <>
      {section === 'general' && (
        <>
          <Row gutter={10}>
            <Col span={24}>
              <FormItem
                label="Անձը հաստատող փաստաթղթի համար (Անձնագիր կամ ՀԾՀ կամ Նույնականացման քարտ)"
                rules={[{ required: true }]}
                {...formItemProp.ssn}
              >
                <Input disabled={disabled} />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <FormItem label="Անուն" rules={[{ required: true }]} {...formItemProp.fname}>
                <ArmenianInput disabled={disabled} />
              </FormItem>
            </Col>
            <Col xs={24} md={12}>
              <FormItem label="Ազգանուն" rules={[{ required: true }]} {...formItemProp.lname}>
                <ArmenianInput disabled={disabled} />
              </FormItem>
            </Col>
          </Row>
        </>
      )}
      {section === 'secondary' && (
        <>
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <FormItem label="Հայրանուն" {...formItemProp.patronymicName}>
                <ArmenianInput disabled={true} />
              </FormItem>
            </Col>
            <Col xs={24} md={12}>
              <FormItem label="Ծննդյան ամսաթիվ"  {...formItemProp.birthdate}>
                <Datepicker style={{ width: '100%' }} disabled={true} />
              </FormItem>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
