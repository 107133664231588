import { createBrowserRouter } from 'react-router-dom';

import { PATHS } from './helpers/constants';
import { Application } from './pages/application';
import { Verify } from './pages/verify';

import { ApplicationLayout } from './components/layouts/application/application-layout';
import { VerifyLayout } from './components/layouts/verify/verify-layout';
import { PublicRoutes } from './components/routes/public-route';
import { MainRoute } from 'components/routes/main-route';
import { PersonEditBankView } from 'components/form/application/person-edit-bank-view';
import { PersonBecomeLeadApplicant } from 'components/form/application/person-become-lead-applicant';

export const router = createBrowserRouter([
  {
    element: <MainRoute />,
    children: [
      {
        element: <PublicRoutes />,
        children: [
          {
            element: <ApplicationLayout />,
            children: [
              {
                path: PATHS.ROOT,
                element: <Application />,
              },
              {
                path: PATHS.APPLICATION,
                element: <Application />,
              },
              {
                path: PATHS.EDIT_BANK_DATA,
                element: <PersonEditBankView />,
              },
              {
                path: PATHS.BECOME_LEAD_APPLICANT,
                element: <PersonBecomeLeadApplicant />,
              },
            ],
          },
          {
            element: <VerifyLayout />,
            children: [
              {
                path: PATHS.VERIFY,
                element: <Verify />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
