import {forwardRef} from 'react';
import {Select} from '.';
import {URL_RELATIVE_TYPES} from '../../api/benefit/constants';
import {useGetFfromBenefit} from '../../api/benefit/use-get-dictionary';
import {FIELD_NAMES_DEFAULT} from './constants';

export const FamilyRelationshipSelect = forwardRef((props, ref) => {
    const {data} = useGetFfromBenefit(URL_RELATIVE_TYPES);
    const filteredData = data.filter(item => item.id !== 3 && item.id !== 4);

    return <Select fieldNames={FIELD_NAMES_DEFAULT} style={{width: '100%'}} options={props.applicantAge < 18 ? filteredData : data} {...props} ref={ref}/>;
});
