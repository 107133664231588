import { Col, Input, Row, message } from 'antd';
import { useValidateCardNumber } from 'api/application/use-validatecard-number';
import { FormSectionCard } from 'components/card/form-section-card';
import { Form } from 'components/form/form';
import { FormItem } from 'components/form/form-item';
import { BanksSelect } from 'components/select/banks-select';
import { VerticalSpace } from 'components/space/vertical-space';

export const BankInfo = ({ setDisable }) => {
  const validateCardNumberMutation = useValidateCardNumber();
  const [messageApi] = message.useMessage();
  const form = Form.useFormInstance();

  const handleCardNumberChange = async (value, bankId) => {
    if (value.length > 16 || value.length === 16) {
      try {
        const response = await validateCardNumberMutation.mutateAsync({
          cardNumber: value,
          bankId: bankId,
        });
        // eslint-disable-next-line no-console
        console.log(response);
        setDisable(false);
      } catch (error) {
        setDisable(true);
        messageApi.open({
          type: 'error',
          content: error,
        });
        // eslint-disable-next-line no-console
        console.error('Error validating card number:', error.message);
      }
    }
  };

  const handleInput = (e) => {
    const { value } = e.target;
    if (value.length > 16) {
      e.target.value = value.slice(0, 16);
    }
  };
  return (
    <VerticalSpace>
      <FormSectionCard title="Իմ բանկային տվյալները">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12}>
            <FormItem label="Սպասարկող բանկը" name="bankId" rules={[{ required: true }]}>
              <BanksSelect />
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <FormItem
              label={'Քարտի համարը (Լրացրեք քարտի վրա գրված 16 նիշը)'}
              name="accountNumber"
              validateTrigger="onChange"
              validateFirst
              shouldUpdate
              rules={[
                { required: true, message: 'Ուշադիր․ Պարտադիր դաշտ' },
                { len: 16, message: 'Տվյալ դաշտը պետք է պարունակի 16 նիշ' },
                {
                  validator: async (_, value) => {
                    if (value) {
                      await handleCardNumberChange(value, form.getFieldValue('bankId'));
                    }
                  },
                },
              ]}
            >
              <Input type="number" rules={[{ type: 'number' }]} min={0} onInput={handleInput} />
            </FormItem>
          </Col>
        </Row>
      </FormSectionCard>
    </VerticalSpace>
  );
};
