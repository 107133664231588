import { useQueryClient } from '@tanstack/react-query';
import { client } from '../client';

export const useFetchData = () => {
  const queryClient = useQueryClient();

  const fetchData = async (url, params, onSuccess, onError) => {
    try {
      const response = await queryClient.fetchQuery([url, params], () => client.post(url, { ...params }));
      onSuccess(response.data);
    } catch (error) {
      onError(error.message);
    }
  };

  return { fetchData };
};
