import { Col, Form, Row, Spin } from 'antd';
import { Input } from '../input';
import { FormItem } from './form-item';
import { PhoneNumberFormItem } from 'components/form-item/phone-number-form-item';
import { genderUtil } from './application/utils';
import { useValidateEmail } from 'api/application/use-validate-email';

export const PersonContactForm = ({ formItemContactProp = {}, parent = [] }) => {
  const form = Form.useFormInstance();
  const value = form?.getFieldValue([...parent, ...formItemContactProp.gender.name]);

  const { mutateAsync, isLoading } = useValidateEmail();

  return (
    <Spin spinning={isLoading}>
      <Row gutter={10} align="middle">
        {formItemContactProp.phoneNumber && (
          <Col xs={24} md={8}>
            <PhoneNumberFormItem {...formItemContactProp.phoneNumber} />
          </Col>
        )}
        <Col xs={24} md={8}>
          <FormItem
            label="Էլ․ փոստի հասցե"
            {...formItemContactProp.email}
            rules={[
              ...formItemContactProp.email.rules,
              {
                message: 'Սխալ էլ․ հասցե',
                validateTrigger: ['onBlur'],
                validator: async (_, value) => {
                  if (!value) {
                    return;
                  }
                  const response = await mutateAsync(value);
                  if (response.data === false) {
                    throw new Error('Սխալ էլ․ հասցե');
                  }
                },
              },
            ]}
          >
            <Input placeholder="example@gmail.com" />
          </FormItem>
        </Col>
        {formItemContactProp.gender && (
          <Col xs={24} md={8}>
            <FormItem label="Սեռ">
              <Input disabled value={genderUtil(value)} />
            </FormItem>
          </Col>
        )}
      </Row>
    </Spin>
  );
};
