import { Card, Col, Row, Spin, Table } from 'antd';
import { Form } from '../../components/form/form';
import { FormItem } from '../../components/form/form-item';
import { ArmenianInput } from 'components/input/armenian-input';
import { VerticalSpace } from 'components/space/vertical-space';
import { Button } from 'components/button';
import { Info } from 'components/typography/info';
import { SearchOutlined } from '@ant-design/icons';
import { URL_GET_USER_CURRENT_STATUS } from 'api/application/constants';
import { useState } from 'react';
import { Input } from 'components/input';
import { useFetchData } from 'api/application/use-fetch-data';

export const Verify = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { fetchData } = useFetchData();

  const rejectionColumns = [
    {
      title: 'Մերժման ամսաթիվը',
      dataIndex: 'rejectedDate',
      key: 'rejectedDate',
    },
    {
      title: 'Մերժման պատճառը',
      dataIndex: 'rejectionReason',
      key: 'rejectionReason',
    },
    {
      title: 'Միջոցառման որոշման համարը',
      dataIndex: 'number',
      key: 'number',
    },
  ];
  const paymentColumns = [
    {
      title: 'Վճարման տրամադրման ամսաթիվ',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Կարգավիճակը',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Գումարը',
      dataIndex: 'money',
      key: 'money',
    },
    {
      title: 'Վճարող',
      dataIndex: 'bank',
      key: 'bank',
    },
    {
      title: 'Միջոցառման որոշման համարը',
      dataIndex: 'number',
      key: 'number',
    },
  ];
  const rejectionDataSource = data?.rejections;
  const paymentDataSource = data?.payments;

  const handleClick = async (values) => {
    setIsLoading(true);
    setError(null);

    const onSuccess = (data) => {
      setData(data);
    };

    const onError = (errorMessage) => {
      setError(errorMessage);
    };

    await fetchData(URL_GET_USER_CURRENT_STATUS, { ...values }, onSuccess, onError);
    setIsLoading(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const onFinish = (values) => {
    handleClick({ ...values });
  };

  const handleCancel = () => {
    setData(null);
    form.resetFields();
  };

  return (
    <>
      <Form name="verify-form" layout="vertical" autoComplete="off" form={form} onFinish={onFinish}>
        <Card title={false} bordered={false}>
          <VerticalSpace size="small">
            <Row justify="center" align="middle" style={{ minHeight: '50vh' }}>
              <Col xs={24} md={16}>
                <VerticalSpace>
                  <Row gutter={20}>
                    <Col>
                      <Info text="Հայտի կարգավիճակը ստուգելու համար լրացրեք անհրաժեշտ դաշտերը և սեղմեք որոնել հրահանգը։"></Info>
                    </Col>
                  </Row>

                  <Row gutter={20}>
                    <Col xs={24} md={12}>
                      <FormItem label="Անուն" rules={[{ required: true }]} name="firstName">
                        <ArmenianInput />
                      </FormItem>
                    </Col>
                    <Col xs={24} md={12}>
                      <FormItem label="Ազգանուն" rules={[{ required: true }]} name="lastName">
                        <ArmenianInput />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col xs={24} md={12}>
                      <FormItem
                        label="Անձնագիր կամ ՀԾՀ կամ նույնականացման քարտ"
                        rules={[{ required: true }]}
                        name="documentNumber"
                      >
                        <Input />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={16} justify="center">
                    <Col>
                      <Button size="large" onClick={handleCancel} shape="round">
                        Մաքրել
                      </Button>
                    </Col>
                    <Col>
                      <Button size="large" shape="round" icon={<SearchOutlined />} type="default" htmlType="submit">
                        Որոնել
                      </Button>
                    </Col>
                  </Row>
                  <Spin spinning={isLoading}>
                    {data && (
                      <Row justify="center">
                        <Col style={{ marginBottom: '10px' }} span={24}>{`${data?.firstName || ''} ${
                          data?.lastName || ''
                        }`}</Col>
                        <Col span={24}>
                          <Table
                            columns={[
                              {
                                title: 'Ընթացիկ կարգավիճակը',
                                dataIndex: 'status',
                                key: 'status',
                              },
                            ]}
                            dataSource={[{ status: data?.status }]}
                          />
                        </Col>
                        {rejectionDataSource && (
                          <Col span={24}>
                            <Table columns={rejectionColumns} dataSource={rejectionDataSource} />
                          </Col>
                        )}
                        {paymentDataSource && (
                          <Col span={24}>
                            <Table columns={paymentColumns} dataSource={paymentDataSource} />
                          </Col>
                        )}
                      </Row>
                    )}
                  </Spin>
                </VerticalSpace>
              </Col>
            </Row>
          </VerticalSpace>
        </Card>
      </Form>
    </>
  );
};
