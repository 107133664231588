import { citizenInfo, files, formItemStepTwoParent } from '../../../pages/application/constants';
import { calculateAge, dateConvert } from 'helpers/utils';
import { DATE_FORMAT_YYYY_MM_DD } from 'helpers/constants';
import dayjs from 'dayjs';

export const citizenData = {
  actualAddress: null,
  animals: [],
  registrationAddress: null,
  citizenInfo: null,
};

export const genderUtil = (gender) => {
  if (gender) {
    return gender.toLowerCase() === 'm' ? 'Արական' : 'Իգական';
  }
};

export const initialValues = {
  // step: null,
  // id: 0,
  // tempApplicationId: null,
  // profileId: '',
  profileData: null,
  citizenData: null,
  familyData: null,
  // applicationProperties: null,
  // applicationType: 1,
};

export const createAnimalsData = (animals) =>
  animals.reduce((acc, current, index) => {
    if (current?.id) {
      return [
        ...acc,
        {
          id: index,
          name: current.name || '',
          count: current.count,
        },
      ];
    }

    return acc;
  }, []);

export const getDataToSave = ({
  values,
  step,
  submittedData,
  citizenInfo,
}) => {
  let dataToSubmit;
  if (step === 0) {
    dataToSubmit = {
      bankInfo: {
        accountNumber: values.accountNumber,
        bankId: values.bankId,
      },
      citizenData: {
        paymentMethod: citizenData.paymentMethod || 0,
        citizenInfo: {
          firstName: citizenInfo.fname,
          lastName: citizenInfo.lname,
          patronymicName: citizenInfo.patronymicName,
          ssn: citizenInfo.ssn,
          birthdate: dateConvert(citizenInfo.birthdate, DATE_FORMAT_YYYY_MM_DD),
          documentType: values.citizenData?.citizenInfo?.documentType || citizenInfo?.documentType,
          documentNumber: values.citizenData?.citizenInfo?.documentNumber || citizenInfo?.documentNumber,
          gender: citizenInfo.gender,
          phoneNumber: values.citizenData?.citizenInfo.phoneNumber,
          email: values.citizenData?.citizenInfo.email,
          age: calculateAge(citizenInfo.birthdate),
        },
        actualAddress: values.citizenData.actualAddress
          ? {
              regionId: values.citizenData.actualAddress.regionId.value,
              region: values.citizenData.actualAddress.regionId.label,
              communityId: values.citizenData.actualAddress.communityId.value,
              community: values.citizenData.actualAddress.communityId.label,
              settlementId: values.citizenData.actualAddress.settlementId.value,
              settlement: values.citizenData.actualAddress.settlementId.label,
              street: values.citizenData.actualAddress.street,
              building: values.citizenData.actualAddress.building,
              buildingType: values.citizenData.actualAddress.buildingType?.label,
              buildingTypeId: values.citizenData.actualAddress.buildingType?.value,
              apartment: values.citizenData.actualAddress.apartment,
              postIndex: values.citizenData.actualAddress.postIndex,
            }
          : null,
        // paymentMethod: 0,
        // electricityAccount: values.citizenData.electricityAccount,
        hasMembersInSameAddress: values?.citizenData?.hasMembersInSameAddress || false,
        isDataAccurate: values?.isDataAccurate?.length ? values?.isDataAccurate[0] : values?.isDataAccurate,
      },
      hasAgreedToPassTo3rdParty: values?.hasAgreedToPassTo3rdParty ?? undefined,
      familyData: { familyMembers: [], hasOtherFamilyMembers: !!values.citizenData.hasMembersInSameAddress },
    };
  } else if (step === 1) {
    dataToSubmit = {
      ...submittedData,

      familyData: {
        // ...values.familyData,
        hasOtherFamilyMembers: true,
        isDataAccurate: values?.isDataAccurate?.length ? values?.isDataAccurate[0] : values?.isDataAccurate,
        familyMembers:
          values.familyData?.familyMembers?.map((member, index) => ({
            id: index,
            citizenInfo: {
              gender: member.gender,
              phoneNumber: member.phoneNumber,
              email: member.email,
              firstName: member.fname,
              lastName: member.lname,
              patronymicName: member.patronymicName,
              ssn: member.ssn,
              birthdate: dateConvert(member.birthdate, DATE_FORMAT_YYYY_MM_DD),
              documentType: member.documentType,
              documentNumber: member.documentNumber,
              age: calculateAge(member.birthdate),
            },
            relativeType: member.relativeType,
            paymentMethod: member.paymentMethod || 0,
            documentChecksum: member?.familyMemberChecksum?.map((item) => item.response.fileChecksum),
          })) || [],
      },
      hasAgreedToPassTo3rdParty: values.hasAgreedToPassTo3rdParty,
    };
  } else if (step === 2) {
    dataToSubmit = {
      ...submittedData,
    };
  } else {
    dataToSubmit = {
      ...submittedData,
      // ...values,
      profileData: values.profileData?.legalRepresentative
        ? {
            ...values.profileData,
            legalRepresentative: values.profileData?.legalRepresentative
              ? {
                  ...values.profileData.legalRepresentative,
                  firstName: values.profileData.legalRepresentative.fname,
                  lastName: values.profileData.legalRepresentative.lname,
                  birthdate: dateConvert(values.profileData.legalRepresentative.birthdate, DATE_FORMAT_YYYY_MM_DD),
                }
              : null,
            legalFilesChecksum: values.profileData?.legalFilesChecksum?.map((item) => item.response.fileChecksum),
          }
        : null,
      // profileId: form.getFieldValue('profileId'),
      // tempApplicationId: form.getFieldValue('tempApplicationId'),
    };
  }
  return dataToSubmit;
};

/** @todo check CREATE citizen info part */
export const setFormCitizenData = (form, data) => {
  if (!data) {
    return;
  }

  form.setFieldsValue({
    citizenData: {
      ...data,
      [files]: {
        militaryServiceFilesChecksum: data.militaryServiceFilesChecksum?.map((item, index) => ({
          uid: item,
          name: `Տեղեկանք ${index + 1}`,
          status: 'done',
          response: {
            fileChecksum: item,
          },
        })),
        studyFilesChecksum: data.studyFilesChecksum?.map((item, index) => ({
          uid: item,
          name: `Տեղեկանք ${index + 1}`,
          status: 'done',
          response: {
            fileChecksum: item,
          },
        })),
        residenceFilesChecksum: data.residenceFilesChecksum?.map((item, index) => ({
          uid: item,
          name: `Տեղեկանք ${index + 1}`,
          status: 'done',
          response: {
            fileChecksum: item,
          },
        })),
      },
      citizenInfo: {
        ...form.getFieldValue([formItemStepTwoParent, citizenInfo]),
        ...data,
        birthdate: data.birthDate ? dayjs(data.birthDate) : null,
      },
      birthdate: data.birthDate ? dayjs(data.birthDate) : null,
      // registrationAddress: data.registrationAddress
      //   ? {
      //       ...data.registrationAddress,
      //       regionId: {
      //         label: data.registrationAddress.region,
      //         value: data.registrationAddress.regionId,
      //       },
      //       communityId: {
      //         label: data.registrationAddress.community,
      //         value: data.registrationAddress.communityId,
      //       },
      //       settlementId: {
      //         value: data.registrationAddress.settlementId,
      //         label: data.registrationAddress.settlement,
      //       },
      //     }
      //   : null,
      actualAddress: data.actualAddress
        ? {
            ...data.actualAddress,
            regionId: {
              value: data.actualAddress.regionId,
              label: data.actualAddress.region,
            },
            communityId: {
              value: data.actualAddress.communityId,
              label: data.actualAddress.community,
            },
            settlementId: {
              value: data.actualAddress.settlementId,
              label: data.actualAddress.settlement,
            },
            buildingType: {
              value: data.actualAddress.buildingTypeId,
              label: data.actualAddress.buildingType,
            },
          }
        : null,
    },
  });
};

export const getDocumentTypeProps = (form, documents, name) => ({
  options:
    documents.map((item) => ({
      value: item.documentType,
      label: item.documentType,
    })) || [],
  onChange: (value) => {
    const documentNumber = documents.find((item) => item.documentType === value).documentNumber;
    form.setFieldValue(name, documentNumber);
  },
});
