import { Input, Col, Row, Spin, Button as SaveBtn, Modal } from 'antd';
import { VerticalSpace } from 'components/space/vertical-space';
import { FormItem } from '../form-item';
import { FormSectionCard } from 'components/card/form-section-card';
import { Form } from '../form';
import { useGetApplicationTypes } from 'api/benefit/use-get-application-types';
import {
  URL_CREATE_APPLICATION_FOR_HOUSEHOLD,
  URL_GET_HOUSHOLDID_DATA_FOR_BANK_CHANGE,
} from 'api/application/constants';
import {
  formItemEditbankData as formItemProp,
  formItemEditbankData,
  formItemActualAddressStepTwo,
} from 'pages/application/constants';
import { REQUEST_TYPES, phonePattern } from '../../../helpers/constants';
import { useBankDataUpdate } from '../../../api/application/useBankDataUpdate';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { errorMessage } from '../../../helpers/utils';
import { ActualResidentialAddress } from './actual-residential-address';
import { GiveDataTo3rdParty } from './give-data-to-3rd-party';
import { IsMyDataTrue } from './is-my-data-true';
import { MinorChildrenAttachedApplication } from './minor-children-attached-application';
import { useValidateEmail } from 'api/application/use-validate-email';

export const PersonBecomeLeadApplicant = ({ title = 'Անձնական տվյալներ' }) => {
  const url = URL_GET_HOUSHOLDID_DATA_FOR_BANK_CHANGE;
  const [data, setData] = useState();
  const [form] = Form.useForm();
  const { state } = useLocation();
  // const navigate = useNavigate();
  // const householdId = form.getFieldValue('householdId');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { mutate } = useBankDataUpdate(URL_CREATE_APPLICATION_FOR_HOUSEHOLD, REQUEST_TYPES.POST, {
    onSuccess: (res) => {
      if (res?.data.accepted) {
        Modal.success({
          title: 'Շնորհակալություն',
          content: <div dangerouslySetInnerHTML={{ __html: res?.data.applicationCreateMessage }} />,
          closable: true,
          onCancel: () => (window.location.href = '/'),
          afterClose: () => (window.location.href = '/'),
          footer: false,
        });
      } else {
        Modal.error({
          title: 'Շնորհակալություն',
          content: <div dangerouslySetInnerHTML={{ __html: res?.data.applicationCreateMessage }} />,
          closable: true,
          onCancel: () => (window.location.href = '/'),
          afterClose: () => (window.location.href = '/'),
          footer: false,
        });
      }
    },
    onError: (error) => {
      errorMessage(error);
    },
  });

  // getting applicant's data
  const { isInitialLoading } = useGetApplicationTypes(
    url,
    {
      ssn: state?.ssn,
    },
    {
      onSuccess: (values) => {
        setData(values?.data);
        form.setFieldsValue({ ...values?.data });
        form.setFieldsValue({ ...values?.data?.bank });
      },
    }
  );

  const { mutateAsync } = useValidateEmail();

  const onFinish = (values) => {
    mutate({
      BankInfo: {
        BankId: values?.bankId,
        AccountNumber: values?.accountNumber,
      },
      FamilyData: null,
      CitizenData: {
        CitizenInfo: {
          FirstName: values?.firstName,
          LastName: values?.lastName,
          PatronymicName: values?.patronymicName,
          Birthdate: values?.birthdate,
          Age: values?.age,
          Ssn: values?.ssn,
          Email: values?.email,
          Gender: values?.sex === 'Արական' ? 'M' : 'F',
          PhoneNumber: values?.phone,
          DocumentType: values?.documentType,
          DocumentNumber: values?.documentNumber,
        },
        ActualAddress: {
          RegionId: values?.citizenData?.actualAddress?.regionId?.value,
          Region: values?.citizenData?.actualAddress?.regionId?.label,
          CommunityId: values?.citizenData?.actualAddress?.communityId?.value,
          Community: values?.citizenData?.actualAddress?.communityId?.label,
          SettlementId: values?.citizenData?.actualAddress?.settlementId?.value,
          Settlement: values?.citizenData?.actualAddress?.settlementId?.label,
          Street: values?.citizenData?.actualAddress?.street,
          Building: values?.citizenData?.actualAddress?.building,
          BuildingTypeId: values?.citizenData?.actualAddress?.buildingType?.value,
          BuildingType: values?.citizenData?.actualAddress?.buildingType?.label,
          Apartment: values?.citizenData?.actualAddress?.apartment,
          PostIndex: values?.citizenData?.actualAddress?.postIndex,
        },
        PaymentMethod: 0,
        isDataAccurate: values?.isDataAccurate[0],
        HasMembersInSameAddress: false,
      },
      HasAgreedToPassTo3rdParty: values?.hasAgreedToPassTo3rdParty,
      MinorChildrenAttachedApplication: values?.minorChildrenAttachedApplication[0],
    });
  };
  return (
    <Spin spinning={isInitialLoading}>
      <Form form={form} name="applicatiion-form" onFinish={onFinish} layout="vertical">
        <VerticalSpace>
          <FormSectionCard style={{ maxWidth: '1200px', margin: '100px auto 10px auto' }} title={title}>
            <VerticalSpace>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={12}>
                  <FormItem
                    label="ՀԾՀ համար/ՀԾՀ չունենալու մասին տեղեկանքի համար"
                    rules={[{ required: true }]}
                    {...formItemProp.ssn}
                  >
                    <Input disabled={true} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="Անուն" rules={[{ required: true }]} {...formItemEditbankData.firstName}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="Ազգանուն" rules={[{ required: true }]} {...formItemEditbankData.lastName}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="Հայրանուն" {...formItemEditbankData.patronymicName}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="Ծննդյան ամսաթիվ" rules={[{ required: true }]} {...formItemEditbankData.birthdate}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={12}>
                  <FormItem
                    label="Էլ.փոստ"
                    {...formItemEditbankData.email}
                    rules={[
                      { required: true },
                      {
                        message: 'Սխալ էլ․ հասցե',
                        validateTrigger: ['onBlur'],
                        validator: async (_, value) => {
                          if (!value) {
                            return;
                          }
                          const response = await mutateAsync(value);
                          if (response.data === false) {
                            throw new Error('Սխալ էլ․ հասցե');
                          }
                        },
                      },
                    ]}
                  >
                    <Input disabled={data?.email !== null && data?.email !== ""} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem
                    label="Հեռախոսահամար"
                    tooltip="Թույլատրվող ֆորմատ +37400000000"
                    rules={[
                      { required: true },
                      {
                        message: 'Հեռախոսահամարը պարտադիր է',
                      },
                      () => ({
                        validator(_, value) {
                          if (!value || value.match(phonePattern)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Սխալ հեռախոսի ֆորմատ, պետք է լինի +374XXXXXXXX ֆորմատով'));
                        },
                      }),
                    ]}
                    {...formItemEditbankData.phone}
                    {...formItemEditbankData.phone}
                  >
                    <Input disabled={data?.phone !== null} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="ԱՀՓ տեսակ " {...formItemEditbankData.documentType}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="ԱՀՓ համար" {...formItemEditbankData.documentNumber}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="Սեռ" {...formItemEditbankData.sex}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="Տարիք" {...formItemEditbankData.age}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
              </Row>
            </VerticalSpace>
          </FormSectionCard>

          <FormSectionCard style={{ maxWidth: '1200px', margin: '0px auto' }} title="Ընտրել նոր բանկային տվյալներ">
            <FormItem hidden name="householdId">
              <Input />
            </FormItem>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12}>
                <FormItem name={'bankId'} hidden />
                <FormItem label="Սպասարկող բանկը" name="bankName">
                  <Input disabled />
                </FormItem>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <FormItem label={'Քարտի համարը'} name="accountNumber">
                  <Input disabled />
                </FormItem>
              </Col>
            </Row>
          </FormSectionCard>
          <ActualResidentialAddress becomeLead={true} formItemActualAddressStepTwo={formItemActualAddressStepTwo} />
          <Row justify={'center'} gutter={16}>
            <div style={{ maxWidth: '1200px' }}>
              <GiveDataTo3rdParty becomeLead={true} />
              <span style={{ margin: 16 }}></span>
              <MinorChildrenAttachedApplication haveMinorChild={data?.haveMinorChild} />
              <span style={{ margin: 16 }}></span>
              <IsMyDataTrue becomeLead={true} />
            </div>
          </Row>
          <Row justify="center">
            <SaveBtn size="large" htmlType="submit">
              Հաստատել
            </SaveBtn>
          </Row>
        </VerticalSpace>
      </Form>
    </Spin>
  );
};
