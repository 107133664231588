import { Col, Modal, Radio, Row, Spin, message } from 'antd';
import { DeleteOutlined, ExclamationCircleFilled, SearchOutlined } from '@ant-design/icons';

import { COLORS, PATHS, REQUEST_TYPES, VALIDATE_MESSAGES } from '../../helpers/constants';
import { Button } from '../button';
// import { WarningSection } from '../card/warning-section';
import { VerticalSpace } from '../space/vertical-space';
// import { Text } from '../typography';
import { PersonMainData } from './person-main-data';
import { useState } from 'react';
import { Form } from './form';
import { useGetValidateUser } from '../../api/benefit/use-get-validate-user';
import { ErrorText } from '../typography/error-text';
import { calculateAge, errorMessage, errorMessageAge } from '../../helpers/utils';
import { SuccessText } from '../typography/success-text';
import { PersonContactForm } from './PersonContactForm';
import { FormItem } from './form-item';
import { useApplication } from 'context/applicaton-context';
import { Input } from 'components/input';
import { useNavigate } from 'react-router-dom';
import { useGetApplicationTypes } from 'api/benefit/use-get-application-types';
import { URL_REFRESH_HOUSEHOLD_DATA } from 'api/application/constants';
import { useBankDataUpdate } from 'api/application/useBankDataUpdate';

const buttonStyle = {
  height: 'auto',
  display: 'flex',
  whiteSpace: 'normal',
};

export const PersonDataSearchForm = ({
  formItemProp,
  url,
  onSuccess,
  onSelect,
  parent = [],
  locationRes,
  // docItemProps,
  setDisabled = false,
  onRemove,
  isApplicant = true,
  checkAge = false,
}) => {
  const [search, setSearch] = useState(false);
  const [radio, setRadio] = useState();
  const [becomeLeadRadio, setBecomeLeadRadio] = useState();
  const [getSsn, setGetSsn] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataChangedMessage, setDataChangedMessage] = useState();
  const [isDataExists, setIsBankDataExists] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = useState();
  const form = Form.useFormInstance();
  const navigate = useNavigate();

  const isApproved = Form.useWatch([...parent, ...(formItemProp?.isApproved?.name || [])], { preserve: true });
  // eslint-disable-next-line no-unused-vars
  const { jump, step } = useApplication();
  const onSearch = () => {
    const ssn = form.getFieldValue([...parent, ...formItemProp.ssn.name]);
    const ssnHasError = form.getFieldError([...parent, ...formItemProp.ssn.name]);
    const firstName = form.getFieldValue([...parent, ...formItemProp.fname.name]);
    const lastName = form.getFieldValue([...parent, ...formItemProp.lname.name]);

    if (!ssn) {
      form.setFields([
        {
          name: [...parent, ...formItemProp.ssn.name],
          errors: [VALIDATE_MESSAGES.required],
        },
      ]);
    }

    if (!firstName) {
      form.setFields([
        {
          name: [...parent, ...formItemProp.fname.name],
          errors: [VALIDATE_MESSAGES.required],
        },
      ]);
    }

    if (!lastName) {
      form.setFields([
        {
          name: [...parent, ...formItemProp.lname.name],
          errors: [VALIDATE_MESSAGES.required],
        },
      ]);
    }
    // if (!birthdate) {
    //   form.setFields([
    //     {
    //       name: [...parent, ...formItemProp.birthdate.name],
    //     },
    //   ]);
    // }
    // if (!birthdate) {
    //   form.setFields([
    //     {
    //       name: [...parent, ...formItemProp.birthdate.name],
    //       errors: [VALIDATE_MESSAGES.required],
    //     },
    //   ]);
    // }
    // if (!birthdate) {
    //   form.setFields([
    //     {
    //       name: [...parent, ...formItemProp.birthdate.name],
    //       errors: [VALIDATE_MESSAGES.required],
    //     },
    //   ]);
    // }

    if (ssn && firstName && lastName && !ssnHasError.length) {
      setSearch({ ssn, firstName, lastName });
    }
  };

  const personSsn = form.getFieldValue([...parent, ...formItemProp.ssn.name]);

  const { mutate } = useBankDataUpdate(`${URL_REFRESH_HOUSEHOLD_DATA}?ssn=${personSsn}`, REQUEST_TYPES.PUT, 
  {
    onSuccess: (res) => {
      Modal.success({
        title: 'Շնորհակալություն',
        content: <div dangerouslySetInnerHTML={{ __html: res?.data?.dataChangeResponseMessage }} />,
        closable: true,
        onCancel: () => (window.location.href = '/'),
        afterClose: () => (window.location.href = '/'),
        footer: false,
      });
      navigate(PATHS.ROOT);
    },
    onError: (error) => {
      errorMessage(error);
    },
  });



  const handleRadioChange = (e) => {
    const value = e.target.value;
    setRadio(value);
    if (value === 3) {
      setBecomeLeadRadio(true);
    } else {
      setBecomeLeadRadio(false);
    }
  };

  const [modalForm] = Form.useForm();

  const handleOk = async () => {
    if (!isDataExists?.isBankDataExists && becomeLeadRadio && !dataChangedMessage) {
      Modal.info({
        title: 'Ուշադրություն',
        content: isDataExists?.message,
        closable: true,
        onCancel: () => (window.location.href = '/'),
        afterClose: () => (window.location.href = '/'),
        footer: false,
      });
    }
    if (radio) {
      setIsModalOpen(false);
      if (radio === 1) {
        form.setFieldValue('applicationId', data?.applicationId);
        jump(step + 1);
      } else {
        if (becomeLeadRadio && isDataExists?.isBankDataExists) {
          navigate(PATHS.BECOME_LEAD_APPLICANT, { state: { ssn: data?.ssn } });
        } else {
          setIsModalOpen(false);
          form.resetFields();
        }
        if (radio === 2) {
          navigate(PATHS.EDIT_BANK_DATA, { state: { ssn: data?.ssn } });
        }
      }
    } else {
      if (dataChangedMessage) {
        mutate(personSsn)
      } else {
        messageApi.open({
          type: 'warning',
          content:
            data?.statusId === 1
              ? 'Անհրաժեշտ է ընտրել դիմումների տեսակներից մեկը'
              : 'Անհրաժեշտ է ընտրել դիմումի տեսակը',
        });
      }
    }
  };

  const handleCancel = () => {
    setBecomeLeadRadio(false);
    setIsModalOpen(false);
    formItemProp.isApproved && form.setFieldValue([...parent, ...formItemProp.isApproved.name], false);
    form.resetFields();
    if (dataChangedMessage) {
      Modal.info({
        title: 'Ուշադրություն',
        content: "Դիմումում փոփոխություններ կատարելու համար խնդրում ենք թարմացնել ձեր տվյալները",
        closable: true,
        onCancel: () => (window.location.href = '/'),
        afterClose: () => (window.location.href = '/'),
        footer: false,
      });
    }
  };
  const { isInitialLoading } = useGetValidateUser(`${url}?isApplicant=${isApplicant}`, search, {
    enabled: !!search.ssn,
    onSuccess: (data) => {
      if (data?.Status === 299) {
        setIsModalOpen(true);
        setDataChangedMessage(data?.Message);
      }
      onSuccess(data);
      if (checkAge && calculateAge(data.birthDate) >= 18) {
        errorMessageAge();
        formItemProp.isApproved && form.setFieldValue([...parent, ...formItemProp.isApproved.name], false);
      } else {
        formItemProp.isApproved && form.setFieldValue([...parent, ...formItemProp.isApproved.name], !!data);
        onSearch();
      }
    },
    select: (data) => onSelect?.(data) || data.data,
    onError: (error) => {
      if (!error.response.data.Data) {
        
        errorMessage(error);
        formItemProp.isApproved && form.setFieldValue([...parent, ...formItemProp.isApproved.name], false);
        form.resetFields();
      } else {
        setGetSsn(error.response.data.Data.Ssn)
        setIsModalOpen(true);
        setData({
          message: error.response?.data?.Message,
          statusId: error?.response?.data?.Data?.StatusId,
          applicationId: error.response.data.Data?.ApplicationId,
          ssn: error.response.data.Data?.Ssn,
        });
      }
      onSearch();
    },
  });
  useGetApplicationTypes(
    PATHS.CHECK_HOUSEHOLD_BANK_DATA,
    { ssn: getSsn ? getSsn : personSsn },
    {
      onSuccess: (data) => {
        setIsBankDataExists(data?.data);
      },
      enabled: radio === 3,
    }
  );
  return (
    <Spin spinning={isInitialLoading}>
      <VerticalSpace>
        <PersonMainData formItemProp={formItemProp} disabled={setDisabled} section="general" />

        <Modal
          title={<ExclamationCircleFilled style={{ color: COLORS.SECONDARY.YELLOW }} />}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Այո"
          cancelText="Ոչ"
        >
          {contextHolder}
          {dataChangedMessage && dataChangedMessage}
          <Form form={modalForm}>
            <p>{data?.message}</p>
            <VerticalSpace>
              {!dataChangedMessage &&
                (data?.statusId === 1 ? (
                  <>
                    <Radio.Group onChange={handleRadioChange}>
                      <VerticalSpace>
                        <Radio value={1}>Ցանկանու՞մ եք ավելացնել անչափահաս</Radio>
                        <Radio value={2}>Ցանկանու՞մ եք խմբագրել բանկային տվյալները</Radio>
                      </VerticalSpace>
                    </Radio.Group>
                  </>
                ) : (
                  <>
                    <FormItem rules={[{ required: true }]}>
                      <Radio.Group onChange={handleRadioChange}>
                        <VerticalSpace>
                          <Radio value={2}>Ցանկանու՞մ եք խմբագրել բանկային տվյալները</Radio>
                          <Radio value={3}>Ցանկանո՞ւմ եք լրացնել առանձին դիմում</Radio>
                        </VerticalSpace>
                      </Radio.Group>
                    </FormItem>
                  </>
                ))}
            </VerticalSpace>
          </Form>
        </Modal>

        <FormItem hidden name="applicationId">
          <Input />
        </FormItem>
        {(locationRes === null || locationRes === undefined) && (
          <Row justify="center">
            <Col>
              {setDisabled ? (
                <Button
                  size="large"
                  icon={<DeleteOutlined />}
                  type="default"
                  onClick={() => onRemove([url, search], () => setSearch(false))}
                >
                  Հեռացնել
                </Button>
              ) : (
                <Button
                  size="large"
                  shape="round"
                  icon={<SearchOutlined />}
                  type="default"
                  onClick={onSearch}
                  style={buttonStyle}
                >
                  <span>Սեղմել կոճակը՝ անձնական տվյալների նույնականացման համար</span>
                </Button>
              )}
            </Col>
          </Row>
        )}
        <PersonMainData formItemProp={formItemProp} disabled={setDisabled} section="secondary" />

        {isApproved === false && <ErrorText text="Նույնականացումը չի հաջողվել" />}
        {isApproved === true && <SuccessText text="Անձը հաջողությամբ նույնականացվեց" />}
        {formItemProp.contact && <PersonContactForm formItemContactProp={formItemProp.contact} parent={parent} />}
        {/* {(locationRes === null || locationRes === undefined) && (
          <WarningSection>
            <Text color={COLORS.SECONDARY.GRAY_DARK}>
              <p>Նույնականացման տվյալները անհրաժեշտ է լրացնել անձը հաստատող փաստաթղթի տվյալներին համապատասխան։</p>
            </Text>
          </WarningSection>
        )} */}
      </VerticalSpace>
    </Spin>
  );
};
