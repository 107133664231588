import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import { Col, Collapse, Modal, notification, Row, Space } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { URL_VALIDATE_USER_DATA } from '../../../../api/benefit/constants';
import { COLORS, FILE_ATTACH_MESSAGE, armenianLettersPattern } from '../../../../helpers/constants';
import { ManageFamilyMemberButton, Button } from '../../../button';
import { Input } from '../../../input';
import { FamilyRelationshipSelect } from '../../../select';
import { VerticalSpace } from '../../../space/vertical-space';
import { Form } from '../../form';
import { FormItem } from '../../form-item';
import { FormSectionCard } from '../../../card/form-section-card';
import { PersonalInfo } from '../personal-info';
import { getDocumentTypeProps } from '../utils';
import { FamilyFileUpload } from './family-file-upload';
import styled from 'styled-components';
import { Info } from 'components/typography/info';
import { SmallText, Text } from 'components/typography';
import { usePostNoMutate } from 'api/application/use-post-no-mutate';
import dayjs from 'dayjs';
import { calculateAge } from 'helpers/utils';
import { useApplication } from 'context/applicaton-context';
import { useQueryClient } from '@tanstack/react-query';
import { PersonDataSearchForm } from 'components/form/person-data-search-form';

const Panel = styled(Collapse.Panel)`
  .ant-collapse-header {
    display: flex;
    flex-wrap: wrap;
  }
`;

const armenianPattern = [
  {
    pattern: armenianLettersPattern,
    message: 'Տեղեկատվությունը սխալ է լրացված',
  },
];

export const useSetFormItemProp = (key) => {
  return {
    fname: {
      name: [key, 'fname'],
      // rules: [...armenianPattern, { required: true, message: 'Ուշադիր․ Պարտադիր դաշտ' }],
    },
    lname: {
      name: [key, 'lname'],
      // rules: [...armenianPattern, { required: true, message: 'Ուշադիր․ Պարտադիր դաշտ' }],
    },
    patronymicName: {
      name: [key, 'patronymicName'],
      rules: armenianPattern,
    },
    ssn: {
      label: (
        <VerticalSpace size={0}>
          <Text>Անձը հաստատող փաստաթղթի համար (Անձնագիր կամ ՀԾՀ կամ Նույնականացման քարտ)</Text>{' '}
          <Text color="red">Խնդրում ենք հնարավորության դեպքում նշել ընտանիքի անդամի ՀԾՀ-ն</Text>
        </VerticalSpace>
      ),
      name: [key, 'ssn'],
      rules: [{ min: 4, max: 15, message: 'Տեղեկատվությունը սխալ է լրացված' }, { required: true }],
    },
    birthdate: {
      name: [key, 'birthdate'],
    },
    isApproved: {
      name: [key, 'isApproved'],
    },
    paymentMethod: {
      name: [key, 'paymentMethod'],
    },
    contact: {
      email: {
        name: [key, 'email'],
        rules: [{ type: 'email', message: 'Սխալ Էլ․ փոստի ֆորմատ' }],
      },
      phoneNumber: {
        name: [key, 'phoneNumber'],
      },
      gender: {
        name: [key, 'gender'],
      },
    },
  };
};

const familyItemDoc = (key) => ({
  documentType: {
    name: [key, 'documentType'],
  },
  documentNumber: {
    name: [key, 'documentNumber'],
    readOnly: true,
  },
});

const isMobile = window.innerWidth <= 768;
// eslint-disable-next-line no-unused-vars
export const FamilyFormForm = ({ field, add, remove }) => {
  const queryClient = useQueryClient();
  const { submittedData } = useApplication();

  const form = Form.useFormInstance();
  const firstName = form.getFieldValue(['familyData', 'familyMembers', field.name, 'fname']);
  const lastName = form.getFieldValue(['familyData', 'familyMembers', field.name, 'lname']);
  const memberSSN = form.getFieldValue(['familyData', 'familyMembers', field.name]);
  const applicantAge = calculateAge(form.getFieldValue(['familyData', 'familyMembers', field.name, 'birthdate']));

  const applicantSSN = Form.useWatch(['citizenData', 'citizenInfo', 'ssn'], { preserve: true });
  const applicantFirstName = Form.useWatch(['citizenData', 'citizenInfo', 'fname'], { preserve: true });
  const applicantLastName = Form.useWatch(['citizenData', 'citizenInfo', 'lname'], { preserve: true });
  const [showInfoText, setShowInfoText] = useState(new Array(field.length).fill(false));
  const [infoText, setInfoText] = useState('');
  const [isFileRequired, setIsFileRequired] = useState(false);
  // const [relativeType, setRelativeType] = useState();

  const applicationId = form.getFieldValue('applicationId');

  const onSuccess = useCallback(
    (data) => {
      form.setFieldValue(['familyData', 'familyMembers', field.name], {
        ...form.getFieldValue(['familyData', 'familyMembers', field.name]),
        ...data,
        birthdate: data.birthDate ? dayjs(data.birthDate) : null,
        isApproved: true,
      });
    },
    [field.name, form]
  );

  const removeFamilyMember = useCallback(() => {
    remove(field.name);
  }, [field.name, remove]);

  const onRemove = useCallback(
    (invalidateRequest, resetState) => {
      removeFamilyMember();
      form.resetFields([['familyData', 'familyMembers', field.name]]);
      resetState();
      queryClient.removeQueries(invalidateRequest);
    },
    [field.name, form, queryClient, removeFamilyMember]
  );

  usePostNoMutate(
    '/api/Application/IsNeedToAttachDocument',
    {
      parentSSN: submittedData?.citizenData?.citizenInfo?.ssn || applicantSSN,
      parentFirstName: submittedData?.citizenData?.citizenInfo?.firstName || applicantFirstName,
      parentLastName: submittedData?.citizenData?.citizenInfo?.lastName || applicantLastName,
      childSSN: memberSSN?.ssn,
      childFirstName: memberSSN?.fname,
      childLastName: memberSSN?.lname,
    },
    {
      // enabled: !!((relativeType === 2 && applicantAge < 18) || (relativeType === 1 && applicantAge < 18) || (relativeType === 4 && applicantAge < 18)),
      enabled: true,
      onSuccess: (res) => {
        setIsFileRequired(!res);
        // setRelativeType(null);
        setInfoText(FILE_ATTACH_MESSAGE.COMMON);
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      },
    }
  );

  const saveFamilyMember = () => {
    const dataToSave = form.getFieldValue(['familyData', 'familyMembers', field.name]);
    form
      .validateFields()
      .then(() => {
        if (!dataToSave.isApproved) {
          Modal.error({
            title: 'Ընտանիքի անդամը չպահվեց',
            content: 'Չի հաջողվել նույնականացնել',
            footer: false,
            closable: true,
          });

          return;
        }

        notification.open({
          message: 'Հաջող գրանցում',
          description: 'Ընտանիքի անդամի տվյալները պահվեցին',
        });
      })
      .catch((errorInfo) => {
        errorInfo.errorFields.forEach((item) => {
          form.scrollToField(item.name);
          return;
        });
      });
  };

  const documentTypeProps = useMemo(
    () =>
      form.getFieldValue(['familyData', 'familyMembers', field.name, 'documentNumber'])
        ? getDocumentTypeProps(
            form,
            [form.getFieldValue(['familyData', 'familyMembers', field.name, 'documentNumber'])],
            familyItemDoc(field.name).documentNumber.name
          )
        : {},
    [field.name, form]
  );

  const handleFamilyRelationshipChange = (value, index) => {
    const updatedInfoTextState = [...showInfoText];
    if ((value === 2 || value === 1 || value === 4) && applicantAge < 18) {
      updatedInfoTextState[index] = true;
      // setRelativeType(value);
    } else {
      updatedInfoTextState[index] = false;
      setIsFileRequired(false);
    }
    setShowInfoText(updatedInfoTextState);
  };

  return (
    <Collapse defaultActiveKey={[field.name]} key={field.name}>
      <Panel
        header={firstName ? `${firstName} ${lastName}` : 'Ընտանիքի անչափահաս անդամի տվյալներ'}
        key={field.name}
        extra={
          <Space>
            <ManageFamilyMemberButton
              doAction={removeFamilyMember}
              text={`${!isMobile ? 'Հեռացնել, եթե ցանկանում եք փոխել դիմողի տվյալները' : ''}`}
              icon={<DeleteOutlined />}
              style={{ color: COLORS.ALERT.RED }}
            />
            {/* <ManageFamilyMemberButton
              doAction={add}
              text={`${!isMobile ? 'Ավելացնել ընտանիքի այլ անդամ' : ''}`}
              icon={<UserAddOutlined />}
            /> */}
          </Space>
        }
      >
        <VerticalSpace>
          <FormSectionCard title="Ընտանիքի անչափահաս անդամի տվյալներ">
            <PersonDataSearchForm
              parent={['familyData', 'familyMembers']}
              formItemProp={useSetFormItemProp(field.name)}
              url={URL_VALIDATE_USER_DATA}
              onSuccess={onSuccess}
              docItemProps={familyItemDoc(field.name)}
              isApplicant={false}
              checkAge={!!applicationId}
              onRemove={onRemove}
              setDisabled={!!memberSSN?.documentNumber}
            />
            <PersonalInfo
              parent={['familyData', 'familyMembers']}
              formItemDoc={familyItemDoc(field.name)}
              selectProps={documentTypeProps}
              viewMode
            />
            {/* {age >= 75 && (
              <FormItem label="Վճարման մեթոդ" rules={[{ required: true }]} name={[field.name, 'paymentMethod']}>
                <PaymentMethodsSelect />
              </FormItem>
            )} */}
          </FormSectionCard>
          <FormItem initialvalue={field.name + 1} name={[field.name, 'id']} hidden>
            <Input />
          </FormItem>
          <FormItem
            label="Ազգակցական կապ Ձեր (դիմողի) նկատմամբ"
            name={[field.name, 'relativeType']}
            rules={[{ required: true }]}
            wrapperCol={{ span: 8 }}
          >
            <FamilyRelationshipSelect applicantAge={applicantAge} onChange={(value) => handleFamilyRelationshipChange(value, field.name)} />
          </FormItem>
          {!isFileRequired || applicantAge >= 0 && applicantAge < 18 && (
            <>
              <Info
                text={
                  <VerticalSpace size="small">
                    <SmallText color={COLORS.PRIMARY.GRAY_LIGHT}>{infoText}</SmallText>
                  </VerticalSpace>
                }
              />
              <FamilyFileUpload memberKey={field.name} isRequired={true} />
            </>
          )}
          <Row justify="center">
            <Col>
              <Space>
                <Button size="large" shape="round" icon={<SaveOutlined />} onClick={saveFamilyMember} type="default">
                  Պահպանել
                </Button>
              </Space>
            </Col>
          </Row>
        </VerticalSpace>
      </Panel>
    </Collapse>
  );
};
