import { FormSectionCard } from '../../../card/form-section-card';
import { PersonAddress } from '../../person-address';

export const ActualResidentialAddress = ({ formItemActualAddressStepTwo = {}, viewMode = false, becomeLead }) => {
  return (
    <FormSectionCard title="Փաստացի բնակության հասցե" style={becomeLead ? { maxWidth: '1200px', margin: '0 auto' } : {}}>
      <PersonAddress formItemAddress={formItemActualAddressStepTwo} viewMode={viewMode} isActualAddress />
    </FormSectionCard>
  );
};
