import { forwardRef } from 'react';
import { Select } from '.';
import { URL_GET_BANK_LIST } from '../../api/benefit/constants';
import { useGetFfromBenefit } from '../../api/benefit/use-get-dictionary';
import { FIELD_NAMES_DEFAULT_BANK } from './constants';
import { Form, Input } from 'antd';

export const BanksSelect = forwardRef((props, ref) => {
  const { data } = useGetFfromBenefit(URL_GET_BANK_LIST);

  return (
    <>
      <Form.Item name={'bankName'} noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Select
        fieldNames={FIELD_NAMES_DEFAULT_BANK}
        placeholder="Ընտրել"
        style={{ width: '100%' }}
        options={data}
        {...props}
        ref={ref}
      />
    </>
  );
});
