import { Input, Col, Row, Spin, Modal, Button as SaveBtn, message } from 'antd';
import { VerticalSpace } from 'components/space/vertical-space';
import { FormItem } from '../form-item';
import { FormSectionCard } from 'components/card/form-section-card';
import { Form } from '../form';
import { useGetApplicationTypes } from 'api/benefit/use-get-application-types';
import {
  URL_GET_HOUSHOLDID_DATA_FOR_BANK_CHANGE,
  URL_GET_OLD_BANK_DATA,
  URL_UPDATE_HOUSEHOLD_BANKDATA,
} from 'api/application/constants';
import { BanksSelect } from 'components/select/banks-select';
import { useValidateCardNumber } from 'api/application/use-validatecard-number';
import { Button } from 'components/button';
import { formItemEditbankData as formItemProp, formItemEditbankData } from 'pages/application/constants';
import { PATHS, REQUEST_TYPES } from '../../../helpers/constants';
import { useBankDataUpdate } from '../../../api/application/useBankDataUpdate';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';

export const PersonEditBankView = ({ title = 'Անձնական տվյալներ' }) => {
  const url = URL_GET_HOUSHOLDID_DATA_FOR_BANK_CHANGE;
  const [showOldbankData, setShowOldbankData] = useState(false);
  const validateCardNumberMutation = useValidateCardNumber();
  const [validisableBtn, setValidDisableBtn] = useState(true);
  const [data, setData] = useState(true);
  const [form] = Form.useForm();
  const { state } = useLocation();
  const navigate = useNavigate();
  const householdId = form.getFieldValue('householdId');

  const handleCardNumberChange = async (value, bankId) => {
    if (value.length > 16 || value.length === 16) {
      try {
        const response = await validateCardNumberMutation.mutateAsync({
          cardNumber: value,
          bankId: bankId,
        });
        // eslint-disable-next-line no-console
        console.log(response);
      } catch (error) {
        if (error.status === 1011) {
          Modal.error({
            title: 'Ուշադրություն',
            content: <div>{error.message}</div>,
            closable: true,
            footer: false,
          });
        }
      }
    }
  };

  const resetFormFields = () => {
    form.resetFields(['bankId', 'accountNumber']);
  };

  const { mutate } = useBankDataUpdate(URL_UPDATE_HOUSEHOLD_BANKDATA, REQUEST_TYPES.POST, {
    onSuccess: (res) => {
      Modal.success({
        title: 'Շնորհակալություն',
        content: <div>{res?.data?.bankChangeResponseMessage}</div>,
        closable: true,
        onCancel: () => (window.location.href = '/'),
        afterClose: () => (window.location.href = '/'),
        footer: false,
      });
      navigate(PATHS.ROOT);
    },
    onError: (error) => {
      Modal.error({
        title: 'Տեղի է ունեցել սխալ',
        content: error.response?.data?.Message,
        footer: false,
        closable: true,
        onCancel: resetFormFields,
      });
    },
  });

  const showOldBankInfo = () => {
    setShowOldbankData(!showOldbankData);
  };

  // getting data for showing person's old card numbers
  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      try {
        const response = await axios.get(process.env.REACT_APP_BASE_URL + URL_GET_OLD_BANK_DATA, {
          params: {
            householdId: householdId,
          },
        });
        setData(response.data);
      } catch (error) {
        message.error(error.message);
      }
    }
    if (householdId) {
      fetchData();
    }
  }, [householdId]);

  // getting applicant's data
  const { isInitialLoading } = useGetApplicationTypes(
    url,
    {
      ssn: state?.ssn,
    },
    {
      onSuccess: (values) => {
        form.setFieldsValue({ ...values?.data });
        form.setFieldsValue({ ...values?.data?.bank });
      },
    }
  );

  const handleInput = (e) => {
    const { value } = e.target;
    if (value.length > 16) {
      e.target.value = value.slice(0, 16);
    }
    if (value.length === 16 || value.length === 17) {
      setValidDisableBtn(false);
    } else {
      setValidDisableBtn(true);
    }
  };

  const onFinish = (values) => {
    mutate({
      householdId: values?.householdId,
      bank: {
        bankId: values?.bankId,
        accountNumber: values?.accountNumber,
      },
    });
  };
  return (
    <Spin spinning={isInitialLoading}>
      <Form form={form} name="applicatiion-form" onFinish={onFinish} layout="vertical">
        <VerticalSpace>
          <FormSectionCard style={{ maxWidth: '1200px', margin: '100px auto 10px auto' }} title={title}>
            <VerticalSpace>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={12}>
                  <FormItem
                    label="ՀԾՀ համար/ՀԾՀ չունենալու մասին տեղեկանքի համար"
                    rules={[{ required: true }]}
                    {...formItemProp.ssn}
                  >
                    <Input disabled={true} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="Անուն" rules={[{ required: true }]} {...formItemEditbankData.firstName}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="Ազգանուն" rules={[{ required: true }]} {...formItemEditbankData.lastName}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="Հայրանուն" {...formItemEditbankData.patronymicName}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="Ծննդյան ամսաթիվ" rules={[{ required: true }]} {...formItemEditbankData.birthdate}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="Էլ.փոստ" {...formItemEditbankData.email}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="Հեռախոսահամար" {...formItemEditbankData.phone}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="ԱՀՓ տեսակ " {...formItemEditbankData.documentType}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="ԱՀՓ համար" {...formItemEditbankData.documentNumber}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="Սեռ" {...formItemEditbankData.sex}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem label="Տարիք" {...formItemEditbankData.age}>
                    <Input disabled={true} />
                  </FormItem>
                </Col>
              </Row>
            </VerticalSpace>
          </FormSectionCard>
          {data.length && (
            <>
              <Button size="large" style={{ margin: '20px auto' }} onClick={showOldBankInfo}>
                {showOldbankData ? 'Փակել' : 'Տեսնել'} հին բանկային տվյալները
              </Button>

              {showOldbankData && (
                <FormSectionCard style={{ maxWidth: '1200px', margin: '0px auto' }} title="Հին բանկային տվյալներ">
                  <VerticalSpace>
                    {data?.map((oldCard, index) => (
                      <Row gutter={16} key={index}>
                        <Col xs={24} sm={24} md={12}>
                          <FormItem label="Սպասարկող բանկը">
                            <Input value={oldCard.bankName} disabled={true} />
                          </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <FormItem label="Քարտի համարը">
                            <Input value={oldCard.accountNumber} disabled={true} />
                          </FormItem>
                        </Col>
                      </Row>
                    ))}
                  </VerticalSpace>
                </FormSectionCard>
              )}
            </>
          )}

          <FormSectionCard style={{ maxWidth: '1200px', margin: '0px auto' }} title="Ընտրել նոր բանկային տվյալներ">
            <FormItem hidden name="householdId">
              <Input />
            </FormItem>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12}>
                <FormItem label="Սպասարկող բանկը" name="bankId" rules={[{ required: true }]}>
                  <BanksSelect />
                </FormItem>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <FormItem
                  label={'Քարտի համարը (Լրացրեք քարտի վրա գրված 16 նիշը)'}
                  name="accountNumber"
                  validateTrigger="onChange"
                  validateFirst
                  shouldUpdate
                  rules={[
                    { required: true, message: 'Ուշադիր․ Պարտադիր դաշտ' },
                    { len: 16, message: 'Տվյալ դաշտը պետք է պարունակի 16 նիշ' },
                    {
                      validator: async (_, value) => {
                        if (value) {
                          await handleCardNumberChange(value, form?.getFieldValue('bankId'));
                        }
                      },
                    },
                  ]}
                >
                  <Input type="number" rules={[{ type: 'number' }]} min={0} onInput={handleInput} />
                </FormItem>
              </Col>
            </Row>
          </FormSectionCard>
          <Row justify="center">
            <SaveBtn size="large" disabled={validisableBtn} htmlType="submit">
              Հաստատել նոր բանկային տվյալները
            </SaveBtn>
          </Row>
        </VerticalSpace>
      </Form>
    </Spin>
  );
};
