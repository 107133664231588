import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { errorMessage } from 'helpers/utils';
import { URL_VALIDATE_BANK_DATA } from './constants';

export const useValidateCardNumber = (restOptions) => {
  const mutation = useMutation(
    (values) => {
      return axios.post(process.env.REACT_APP_BASE_URL + URL_VALIDATE_BANK_DATA, values, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
    {
      ...restOptions,
      onSuccess: (data, variables, context) => {
        restOptions?.onSuccess?.(data, variables, context);
      },
      onError: errorMessage,
    }
  );
  return mutation;
};
